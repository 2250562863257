import React from 'react';
import FullHeight from 'react-full-height';
import './home.css';

const Home = () => {
    return (
        <FullHeight className="section" id="home">
            
        </FullHeight>
    )
}
export default Home;