import React from 'react';
import { Link } from 'react-router-dom';
import Scrollspy from 'react-scrollspy'
import './navbar.css';

const Navbar = () => {

    return (
        <div className="navBar">
            <Scrollspy items={ ['home', 'therapies', 'aboutMe', 'maps', 'contact'] } currentClassName="active" offset={-46}>
                <li>
                    <Link
                        to='/#page-home'>
                        Home
                    </Link>
                </li>
                <li>
                    <Link
                        to="/#page-therapies">
                        Therapien
                    </Link>
                </li>
                <li>
                    <Link
                        to="/#page-aboutMe">
                        Über mich
                    </Link>
                </li>
                <li>
                    <Link
                        to="/#page-maps">
                        Karte
                    </Link>
                </li>
                <li>
                    <Link
                        to="/#page-contact">
                        Kontakt
                    </Link>
                </li>
            </Scrollspy>
        </div>
    );
};
export default Navbar;