import React from 'react'
import './maps.css';
import FullHeight from 'react-full-height';



const MapContainer = () => {
    return (
        <FullHeight className="section" id="maps">
                <iframe title="map-rgb" width="100%" height="100%" className="gmap_canvas" src="https://maps.google.com/maps?q=ziegetsdorfer%20strasse%2032%2093051%20regensburg&t=&z=15&ie=UTF8&iwloc=&output=embed" scrolling="no"></iframe>
        </FullHeight>

    );
}


export default MapContainer;