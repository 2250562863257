import React from 'react';
import Navbar from './components/navbar/navbar';

import "./impress.css";
import "./pages/pages.css";

const Impress = () => {
    return (
        <div>
        <Navbar />

        <div className="section background" >
            <div className="viewport">
                <div>
                <h1>Impressum</h1>

                <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
                <p>Nadezda L&ouml;we<br />
                    Naturheilpraxis Nadja L&ouml;we<br />
                    Ziegetsdorfer Str. 32<br />
                    93051 Regensburg</p>

                <h2>Kontakt</h2>
                <p>Telefon: +49177 6962490<br />
                    E-Mail: admin@hp-loewe.com</p>

                <h2>Aufsichtsbeh&ouml;rde</h2>
                <p>Gesundheitsamt / Landratsamt Kelheim<br />
                    &#91;Anschrift der Aufsichtsbeh&ouml;rde&#93;</p>
                <p><a href="http://" target="_blank" rel="noopener noreferrer">http://</a></p>

                <h2>Berufsbezeichnung und berufsrechtliche Regelungen</h2>
                <p>Berufsbezeichnung:<br />
                    Heilpraktiker</p>
                <p>Zust&auml;ndige Kammer:<br />
                </p>
                <p>Verliehen in:<br />
                </p>
                <p>Es gelten folgende berufsrechtliche Regelungen:</p>
                <h2>Angaben zur Berufs&shy;haftpflicht&shy;versicherung</h2>
                <h2>Redaktionell verantwortlich</h2>
                <p>&#91;Anschriften und Themengebiete der verantwortlichen Personen&#93;</p>

                <h2>EU-Streitschlichtung</h2>
                <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

                <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
                <p>Wir nehmen an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil. Zust&auml;ndig ist die Universalschlichtungsstelle des Zentrums f&uuml;r Schlichtung e.V., Stra&szlig;burger Stra&szlig;e 8, 77694 Kehl am Rhein (<a href="https://www.verbraucher-schlichter.de" rel="noopener noreferrer" target="_blank">https://www.verbraucher-schlichter.de</a>).</p>

                <p>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>
            </div>
            </div>

        </div>
        </div>
    )
}
export default Impress;