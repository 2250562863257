import React from 'react';
import { HashScroll } from 'react-hash-scroll';


import Navbar from './components/navbar/navbar';
import Home from './pages/home/home';
import Contact from './pages/contact/contact';
import Maps from './pages/maps/maps';
import Therapies from './pages/therapies/therapies';
import AboutMe from './pages/aboutme/aboutme';
import Credits from './pages/credits/credits';

import Privacy from './privacy';
import Impress from './impress';


import './pages/pages.css'

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
          <div>
            <HashScroll hash={'page-home'}><div><Home /></div></HashScroll>
            <Navbar />

            <div className="background">
              <HashScroll behavior={"smooth"} hash={'page-therapies'}><div><Therapies /></div></HashScroll>
              <HashScroll behavior={"smooth"} hash={'page-aboutMe'}><div><AboutMe /></div></HashScroll>
              <HashScroll behavior={"smooth"} hash={'page-maps'}><div><Maps /></div></HashScroll>
              <HashScroll behavior={"smooth"} hash={'page-contact'}><div><Contact /></div></HashScroll>
              <HashScroll behavior={"smooth"} hash={'page-credits'}><div><Credits /></div></HashScroll>

            </div>
          </div>
        }>
        </ Route>
        <Route path="privacy" element={<Privacy />} />
        <Route path="impress" element={<Impress />} />

      </Routes>
    </BrowserRouter>

  )
}
export default App;