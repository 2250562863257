import React from 'react';
import './credits.css';

const Credits = () => {
    return (
        <div id='credits'>
            <a href="https://de.freepik.com/fotos/gesundheit">Gesundheit Foto erstellt von freepik - de.freepik.com</a>
            <a href="https://de.freepik.com/vektoren/hintergrund">Hintergrund Vektor erstellt von pikisuperstar - de.freepik.com</a>
            <a href="https://de.freepik.com/fotos/lebensmittel">Lebensmittel Foto erstellt von freepik - de.freepik.com</a>
            <a href="https://de.freepik.com/fotos/frau">Frau Foto erstellt von Racool_studio - de.freepik.com</a>
        </div>
    )
}
export default Credits;