import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CookieBanner } from '@keepist/react-gdpr-cookie-banner';
import ReactGA from 'react-ga4';

import './index.css';
import './css/CookieBanner.css'

ReactDOM.render(
  <React.StrictMode>
    <App />
    <CookieBanner
      message="Diese Website verwendet Cookies. Bitte stimmen Sie der Verwendung zu."
      policyLink="/privacy"
      privacyPolicyLinkText={'Datenschutzerklärung'}
      necessaryOptionText={'Erforderlich'}
      statisticsOptionInitiallyChecked={true}
      statisticsOptionText={'Statisktiken (Google Analytics)'}
      onAcceptStatistics = {() => {
        ReactGA.initialize('G-VKBME9JYPG', {
          gaOptions: {
            anonymizeIp: true
          }
        });
      }}
      
      showPreferencesOption = {false}
      showMarketingOption = {false}
      showAcceptSelectionButton = {true}
      acceptSelectionButtonText = {'Auswahl akzeptieren'}
      acceptAllButtonText = {'Alle akzeptieren'}
      styles={{
        button: null,
        dialog: null,
        optionWrapper: null,
        checkbox: null,
        policy: null,
        message: null
      }}

    />
  </React.StrictMode>,
  document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();