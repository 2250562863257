import { Icon } from '@iconify/react';
import React from 'react';
import { Link } from 'react-router-dom';

import './contact.css';

const Impress = () => {
        return (
                <div className="section sectionBackground" id="contact">
                        <div className="viewport">
                                <div className='impressItem'>
                                        <h1>Kontakt</h1>
                                        <table>
                                                <tr>
                                                        <td className='impressIconContainer impressDetailsCell'>
                                                                <Icon className='impressIcon' icon={'mdi:home'} />
                                                        </td>
                                                        <td className='impressDetailsCell'>
                                                                <b>Standort Regensburg</b>

                                                        </td>
                                                </tr>
                                                <tr>
                                                        <td />
                                                        <td className='impressDetailsCell'>
                                                                Ziegetsdorfer Str. 32<br />
                                                                93051 Regensburg
                                                        </td>
                                                </tr>
                                                <tr>
                                                        <td className='impressIconContainer impressDetailsCell'>
                                                                <Icon className='impressIcon' icon={'mdi:phone'} />
                                                        </td>
                                                        <td className='impressDetailsCell'>
                                                                <a href='tel:+49177 6962490'>0177 6962490</a>
                                                        </td>
                                                </tr>
                                                <tr>
                                                        <td className='impressIconContainer impressDetailsCell'>
                                                                <Icon className='impressIcon' icon={'mdi:at'} />
                                                        </td>
                                                        <td className='impressDetailsCell'>

                                                                <a href='mailto:nloewe@gmx.de'>nloewe@gmx.de</a>

                                                        </td>
                                                </tr>
                                                <tr>
                                                        <td className='impressIconContainer impressDetailsCell'>
                                                        </td>
                                                        <td className='impressDetailsCell'>

                                                                <Link to={'/impress'}>Impressum</Link>

                                                        </td>
                                                </tr>
                                                <tr>
                                                        <td className='impressIconContainer impressDetailsCell'>
                                                        </td>
                                                        <td className='impressDetailsCell'>

                                                                <Link to={'/privacy'}>Datenschutzerklärung</Link>

                                                        </td>
                                                </tr>
                                        </table>
                                </div>
                                <div className='impressItem' id='social'>
                                        Finden Sie mich auf Social Media:<p />
                                        <a href='https://www.facebook.com/hploewe'>
                                                <Icon className='impressSocialIcon' icon={'mdi:facebook'} />
                                        </a>

                                </div>
                        </div>
                </div>
        )
}
export default Impress;