import React from 'react';

import './aboutme.css';

const AboutMe = () => {
        return (
                <div className="section sectionBackground" id="aboutMe">
                        <div className="viewport">
                                <div className='aboutMeItem' id='qualifications'>
                                        <h1>Über mich</h1>
                                        <h2>Meine Qualifikationen</h2>
                                        <ul>
                                            <li>Ausbildung zur Heilpraktikerin an der Heilpraktikerschule Campus Regensburg</li>
                                            <li>Spezialisierung in: TCM, Komplexhomöopathie, Kinesiologie, Wirbelsäulentherapie und in Frequenzbehandlungsmethoden</li>
                                            <li>Aktive Mitgliedschaft beim Bund Deutscher Heilpraktiker und Naturheilkundiger e.V.</li>
                                            </ul>
                                </div>
                                <div className='aboutMeItem' id='aboutMeImage'>
                                        
                                  

                                </div>
                        </div>
                </div>
        )
}
export default AboutMe;